import { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './i18n';
import * as CommonMethods from './CommonMethods';
import * as State from './State';
// import Popup from './Components/Popup';
import LoadingScreen from './Components/LoadingScreen';
import MobileNav from './Components/MobileNav';
import Home from './Components/Home';
import GeneralAnnouncements from './Components/GeneralAnnouncement';
import SpecialBanner from './Components/SpecialBanner';
import AboutUs from './Components/AboutUs';
import OurValues from './Components/OurValues';
import OurPhilosophy from './Components/OurPhilosophy';
import OurProducts from './Components/OurProducts';
import Careers from './Components/Careers';
import OurOffices from './Components/OurOffices';
import OurHistory from './Components/OurHistory';
import Announcements from './Components/Announcements';
import ContactUs from './Components/ContactUs';
import Overlay from './Components/Overlay';

function App() {
  const [popupData, setpopupData] = useState({ isSlide: true });

  return (
    <Router>
      <Suspense fallback={<LoadingScreen />} >
        <div className="App">
          <MobileNav />
          <Home />
          <SpecialBanner />
          <AboutUs CommonMethods={CommonMethods} />
          <OurValues State={State} />
          <OurPhilosophy />
          <OurProducts />
          <Careers />
          <OurOffices />
          <OurHistory />
          <Announcements />
          <ContactUs />
          <Footer />
          <Overlay />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;

const Footer = lazy(async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return import('./Components/Footer');
});
